.form__message {
    font-size: 1.6rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-error);
    display: block;
    text-align: left;
    margin-top: 1rem;
    box-sizing: border-box;
    order: 10;

    a {
        color: var(--color-error) !important;
        font-weight: var(--font-weight-regular) !important;
    }

    .-fun & {
        color: var(--color-fun);

        a {
            color: var(--color-fun);
        }
    }

    .-family & {
        color: var(--color-family);

        a {
            color: var(--color-family);
        }
    }

    .-well-informed &, .poll__content & {
        color: var(--color-well-informed);

        a {
            color: var(--color-well-informed);
        }
    }

    &.-error {
        color: var(--color-error);

        a {
            color: var(--color-error);
        }
    }

    .newsletter & {
        background: var(--color-purple);
        padding: 1.5rem;
        border-radius: 2.5rem;
        text-align: center;
        position: absolute;
        top: calc(100% + .5rem);
        width: 100%;
    }

    &::before {
        content: '';
        background-image: url('error.svg');
        display: inline-block;
        width: 1.9rem;
        height: 1.1rem;
        margin-right: .7rem;

        @supports (mask-repeat: no-repeat) {
            mask-image: url('error.svg');
            mask-size: 1.9rem 1.1rem;
            background: currentColor;
        }
    }

    .form__entry.-checkbox &, .form__entry:has(.form__checkbox) & {
        margin-bottom: 2rem;
    }

    .alert.-error & {
        margin-top: 0;
        text-align: center;
    }
}
